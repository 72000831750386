.sidebar {
  max-width: 280px;
}
.navLink {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--gray-700);
}
.navLink:hover,
.navLink:focus,
.navLink:hover .navIcon,
.navLink:focus .navIcon {
  color: #00448f !important;
}
.navLink:global(.active),
.navLink:global(.active) .navIcon {
  color: #00448f !important;
  background-color: var(--blue-vivid-100) !important;
}
.navIcon {
  font-size: 1.25rem;
  color: var(--primary-light-gray);
}
