.link {
  color: var(--gray-400);
  opacity: 0.7;
  transition: all 0.3 ease-in-out;
}

.link:hover,
.link:focus {
  color: var(--gray-400);
  opacity: 1;
}

.linkActive {
  color: var(--gray-400);
  opacity: 1;
}
