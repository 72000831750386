.categoryList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

@media screen and (min-width: 1200px) {
  .categoryList {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }
}
