.wrap {
  display: flex;
  align-items: center;
  position: relative;
}

.widgetCol {
  margin-right: 8px;
}

@media only screen and (min-width: 320px) {
  .widgetCol span {
    display: none;
  }
}
@media only screen and (min-width: 550px) {
  .widgetCol span {
    display: block;
  }
}

@media only screen and (max-width: 1599px) {
  .widgetCol {
    margin-right: 0;
  }
}

.dropdown {
  display: none;
  position: absolute;
  padding: 0;
  top: 44px;
  left: -100px;
  right: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid var(--gray-300);
  box-shadow: 0 2px 5px 5px rgba(0, 0, 0, 0.1);
  min-width: 180px;
  z-index: 9999;
}

.dropdownShow {
  display: block;
}

.icon {
  color: var(--gray-600);
  margin-right: 4px;
  margin-right: 8px;
  font-size: 21px;
}

.link {
  display: block;
  width: 100%;
  padding: 6px 6px;
  border: none;
  border-bottom: 1px solid var(--gray-100);
  color: var(--gray-700);
  text-align: left;
  transition: all 0.15s ease-in-out;
  background-color: transparent;
}

.signout {
  border-bottom: 0;
}

.link:hover,
.link:focus,
.link:hover .icon,
.link:focus .icon {
  color: var(--blue-500);
}

.link:hover,
.link:focus {
  background-color: var(--blue-100);
}

@media only screen and (max-width: 767px) {
  .dropdown {
    left: auto;
    min-width: 120px;
  }
}
