.topLink {
  font-size: 13px;
}

.mcfxLogoLink {
  margin-bottom: 34px;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
}

.verticalCenter {
  margin: auto;
}

.page {
  background-color: #ffffff;
  display: flex;
  min-height: 100vh;
}

.form {
  flex-basis: 33.33%;
}

.animation {
  max-width: 66.66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fafbfd;
  flex-basis: 66.66%;
  text-align: center;
}
@media only screen and (max-width: 900px) {
  .animation {
    display: none;
  }
  .form {
    width: 100%;
  }
  .page {
    justify-content: center;
  }
}

.mcfxLogo {
  width: 223px;
  height: auto;
  margin: 0 auto;
}

.webfxLogo {
  max-width: 64px;
  height: auto;
}
