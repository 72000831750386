.office365Button {
  font-size: 14px;
  letter-spacing: 0.25px;
  border: 1px solid #dadce0;
  height: auto;
  min-height: 40px;
  padding: 0 10px;
  width: 372px;
}

.office365text {
  font-family: 'Google Sans', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  padding: 5px;
  margin: auto;
}

.office365Icon {
  float: left;
  margin-left: 3px;
}

.office365Button:hover {
  color: #3c4043;
  background-color: #f8fafe;
  border-color: #d2e3fc;
}

.spinner {
  margin-right: auto;
  margin-left: auto;
  margin-top: 5px;
}
