.modalContent {
  border-radius: 8px;
  border: 1px solid #083a74;
  box-sizing: border-box;
  box-shadow: 0px 2px 18px rgba(76, 116, 185, 0.12);
  filter: drop-shadow(0px 2px 18px rgba(76, 116, 185, 0.12));
  overflow: hidden;
  color: white;
  background-image: url('../../../assets/images/popup.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin: -2px;
}

.modalDialog {
  width: 1148px;
  left: 8%;
  top: 15%;
}

.close {
  margin-left: auto;
  font-size: 25px;
  cursor: pointer;
}

.closeWrapper {
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
}

@media only screen and (min-width: 1920px) {
  .modalDialog {
    left: 5%;
  }
}

.card {
  flex: 1 1 auto;
  padding: 1rem;
  margin: 1rem;
}

.video-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.thumbnails {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-top: 10px;
}

.thumbnail {
  width: 16rem;
  height: auto;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
}

video {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.unselected {
  opacity: 0.3;
}
