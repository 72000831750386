.articleContainer {
  display: flex;
  min-height: 100vh;
}

.articleSection {
  margin-left: auto;
  margin-right: auto;
  flex: 1;
}

.avatar {
  width: 32px !important;
  height: 32px !important;
}

/* Content Styles */
.content {
  font-size: 14px;
  line-height: 1.5;
  color: #1f2328;
}

.content img {
  max-width: 800px;
}

/* Headings */
.content h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.1;
  margin: 16px auto 12px;
}

.content h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  margin: 16px auto 12px;
}

.content h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 16px auto 12px;
}

/* Paragraphs */
.content p {
  margin: 12px 0;
}

/* Inline Code */
.content p code {
  background-color: #f5f7fa;
  color: #090b0e;
  font-family: SourceCodePro, Monaco, Menlo, Consolas, 'Courier New', Courier, monospace;
  line-height: 1.3;
  padding: 5px;
}

/* Code Blocks */
.content pre {
  background-color: #f6f8fa;
  border-radius: 6px;
  padding: 16px;
  overflow-x: auto;
  margin: 0;
  counter-reset: line;
}

.content pre code {
  font-family: ui-monospace, SFMono-Regular, 'SF Mono', Menlo, Consolas, 'Liberation Mono',
    monospace;
  font-size: 12px;
  line-height: 20px;
  white-space: pre;
  display: block;
}

.content pre code::before {
  counter-increment: line;
  content: counter(line);
  color: #6e7781;
  display: inline-block;
  width: 1em;
  margin-right: 16px;
  text-align: right;
  user-select: none;
}

/* Links within Code */
.content pre code a {
  color: #0969da;
  text-decoration: none;
}

.content pre code a:hover {
  text-decoration: underline;
}

/* Table styles */
.content table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e1e4e8;
  font-size: 14px;
  margin: 20px 0;
}

.content td {
  border: 1px solid #e1e4e8;
  padding: 8px;
  max-width: 100px;
  word-wrap: break-word;
}

/* Header row styles */
.content tr:first-child td {
  font-weight: bold;
}

/* Alternating row colors */
.content tr:nth-child(even) {
  background-color: #f6f8fa;
}

/* Remove margin from paragraphs inside table cells */
.content td p {
  padding: 10px;
  margin: 0;
}
