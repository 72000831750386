.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  padding: 20px;
  background-color: var(--table-hover-bg);
}

.articlesWrapper {
  overflow-y: auto;
  flex: 1;
  padding: 20px;
}

.border {
  border-radius: 5px;
  border: 1px solid #d2dcee;
  overflow: hidden;
}

.link {
  color: #207de9 !important;
}

.footerLink {
  color: #207de9 !important;
  font-size: 16px;
}

.footer {
  padding-top: 10px;
  border-top: 1px solid var(--gray-200);
}

.desc {
  color: #829dce !important;
}

.title {
  color: #374046;
}

.helpIcon {
  width: 30px;
  margin-right: 15px;
}

.docIcon {
  width: 24px;
}

.articleTitle {
  color: #374046;
  font-weight: 600;
}

.articleDesc {
  color: #6e7e86;
}

.article {
  cursor: pointer;
}
