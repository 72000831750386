.main {
  background: var(--warning);
  height: 50px;
  color: #fff;
}

.imgWrap {
  height: 36px;
  width: 36px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid transparent !important;
  cursor: default !important;
}
.imgWrap:hover {
  box-shadow: none !important;
}

.swapButton {
  color: #fff;
  font-size: 24px;
  padding: 0px;
  margin-left: 5px;
}
.swapButton:hover {
  text-decoration: none;
  color: var(--gray-700);
}
