.wrap {
  position: relative;
}

.supportTooltip {
  z-index: 100000;
  background-color: var(--dark-grey);
  color: var(--white);
  width: 158px;
}

@media only screen and (min-width: 320px) {
  .widgetCol span {
    display: none;
  }
}

@media only screen and (min-width: 550px) {
  .widgetCol span {
    display: block;
  }
}

@media only screen and (max-width: 1599px) {
  .widgetCol {
    margin-right: 0;
  }
}

.dropdown {
  display: none;
  position: absolute;
  padding: 6px 0;
  top: 44px;
  left: -70px;
  right: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid var(--gray-300);
  box-shadow: 0 2px 5px 5px rgba(0, 0, 0, 0.1);
  min-width: 200px;
}

.dropdownShow {
  display: block;
}

.link {
  display: block;
  width: 100%;
  padding: 4px 6px;
  border: none;
  border-bottom: 1px solid var(--gray-100);
  color: var(--gray-700);
  text-align: left;
  transition: all 0.15s ease-in-out;
  background-color: transparent;
}

.link:hover,
.link:focus {
  color: var(--blue-500);
  background-color: var(--gray-200);
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .dropdown {
    left: auto;
    min-width: 120px;
  }
}
