.name:hover {
  text-decoration: underline !important;
}

.avatar {
  width: 20px !important;
  height: 20px !important;
}

.content em,
.name em {
  background: rgba(0, 0, 139, 0.15);
  border-radius: 4px;
  padding: 0 2px;
}
