.navButtonsWrapper {
  border-right: 1px solid var(--blue-300);
  border-left: 1px solid var(--blue-300);
  min-height: 69px;
  display: none;
  position: relative;
}

.navButtonsWrapper::before {
  background: linear-gradient(90deg, rgba(76, 116, 185, 0) 0%, var(--blue-400) 75%);
  content: '';
  height: 100%;
  position: absolute;
  left: -31px;
  top: 0;
  pointer-events: none;
  width: 30px;
  z-index: 1;
}

.navButton span {
  font-size: 24px;
}

.navButton {
  padding: 12px 17px;
}

@media only screen and (max-width: 1250px) {
  .navButtonsWrapper {
    display: flex;
  }
}

@media only screen and (max-width: 1700px) {
  .navButtonsWrapper {
    display: flex;
  }
}

@media only screen and (max-width: 600px) {
  .navButtonsWrapper {
    display: none;
  }
}
