.Toastify__toast-container {
  width: 500px !important;
}

.Toastify__toast-icon {
  margin-inline-end: 10px;
  -webkit-margin-end: 10px;
}

/* .Toastify__toast-container--top-center{
    margin-left: -250px !important;
}

.Toastify__toast-container--bottom-center{
    margin-left: -250px !important;
} */
