.avatar {
  width: 32px !important;
  height: 32px !important;
}

.articleCard {
  border: 1px solid var(--gray-300);
  max-width: 700px;
  word-break: break-all;
  transition: all 0.3s ease;
}

.articleCardLink:hover .articleCard {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.preview {
  word-break: break-word;
}
