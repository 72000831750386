.topLink {
  font-size: 13px;
}

.mcfxLogoLink {
  margin-bottom: 34px;
}

@media only screen and (max-width: 790px) {
  .title {
    font-size: 22px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 440px) {
  a:first-child {
    margin-bottom: 20px;
  }
}

.page {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.form {
  flex-basis: 33.33%;
  align-self: center;
}

.animation {
  max-width: 66.66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fafbfd;
  flex-basis: 66.66%;
  text-align: center;
}
@media only screen and (max-width: 790px) {
  .animation {
    background: transparent;
    margin-top: 3rem;
  }
}

.mcfxLogo {
  width: 223px;
  height: auto;
  margin: 0 auto;
}

.webfxLogo {
  max-width: 64px;
  height: auto;
}

@media only screen and (max-width: 790px) {
  .page {
    flex-direction: column;
  }

  .animation {
    background: transparent;
    padding-top: 60px;
  }

  .animation,
  .form {
    width: 100%;
    max-width: 100%;
  }

  .poweredByWrapper {
    display: none !important;
  }
}
