@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slideDown {
  animation: slideDown 0.6s ease-out;
}

.logo {
  height: 20px;
}
