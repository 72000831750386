.container {
  width: 100%;
  padding: 0 20px 40px 20px;
  max-width: 532px;
  margin-bottom: 40px;
}

.title {
  font-size: 28px !important;
  line-height: 36px !important;
  margin-bottom: 45px !important;
}

.formWrapper {
  margin-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
}

.btnWrap {
  margin-bottom: 24px;
}

.inputLabel {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  word-spacing: 1px;
  margin-bottom: 0.5rem;
}

.input {
  font-size: 14px;
  font-weight: 400;
}

.submitButton {
  font-size: 12px;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
}

.alert {
  color: white;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  padding: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--red-04);
}

.alert button {
  position: absolute;
  right: 10px;
  bottom: 3px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
}

.separatorWrapper {
  display: flex;
  margin-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
}

.separatorWrapper hr {
  flex-grow: 1;
}

.separatorText {
  padding: 0 31px;
  font-size: 12px;
  font-weight: 500;
  line-height: 34px;
  color: var(--primary-light-gray);
  margin: 0;
}

.optionsWrapper {
  align-items: center;
  display: flex;
  margin-bottom: 42px;
}

.rememberLog {
  display: flex;
  flex-grow: 1;
}

.optionsWrapper p,
.optionsWrapper button {
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
}

.signWithWrapper div[role='button'] {
  height: 55px;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}
@media only screen and (max-width: 1115px) {
  .signWithWrapper {
    flex-direction: column;
  }

  .googleButton,
  .facebookButton {
    width: 100%;
  }

  .googleButton {
    margin-bottom: 8px;
  }

  .container {
    margin-bottom: 112px;
  }
}

@media only screen and (max-width: 790px) {
  .signWithWrapper {
    flex-direction: row;
  }

  .googleButton,
  .facebookButton {
    width: auto;
  }

  .googleButton {
    margin-bottom: 0px;
  }

  .container {
    margin-bottom: 60px;
  }
}
