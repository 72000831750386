.activeTab {
  background-color: var(--blue-700);
}

.activeTab :global(.nav-product-logo),
.tab:hover :global(.nav-product-logo) {
  color: var(--white);
}

.navlink {
  color: var(--blue-700);
  display: inline-flex;
  align-items: center;
  padding: 20px;
}
