/* .avatarRow {
  margin-left: 0 !important;
}

.avatarCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin: 5px 0 16px 0;
  /* background-color: var(--color-cornflower-blue); */
/* border-radius: 4px; */
/*} */

.alert {
  font-size: 13px;
  font-weight: 500;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.toggle {
  /* width: 40px; */
  /* text-align: center */
}

.toggle + .toggle {
  border-left: 1px solid var(--gray-200);
}

.toggleBtn {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: var(--gray-500);
}

.toggleBtn:hover,
.activeToggleBtn {
  color: var(--blue-400);
  background-color: transparent;
}

.avatarTitle {
  margin-top: 10px;
}

.avatarPicture {
  width: 140px;
  height: 140px;
}

.linkStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--danger);
  font-size: 0.9rem;
  border-radius: 4px;
  transition: all ease-in-out 0.15s;
}

.linkStyle:hover {
  background-color: var(--danger);
  color: var(--light);
}

@media all and (max-width: 768px) {
  .avatarRow {
    margin-right: 0 !important;
  }

  .firstLastNames {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.containerFluid {
  position: relative;
  min-height: 400px;
}

.avatarUploaderPlaceholder {
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  z-index: 1;
  width: 400px;
}

.formContentRow {
  margin-top: 50vh;
}

@media all and (min-width: 992px) {
  .avatarUploaderPlaceholder {
    width: 200px;
  }

  .formContentRow {
    margin-top: 0;
  }
}

@media all and (min-width: 1100px) {
  .avatarUploaderPlaceholder {
    width: 160px;
  }
}
