.sideMenu {
  position: sticky;
  top: 20px;
  height: fit-content;
}

.menuList {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  gap: 12px;
}

.header {
  border-bottom: 1px solid var(--gray-300);
}

.menuItem {
  font-size: 14px;
  padding-left: calc(20px + 0.5rem); /* 20px + ml-2 */
}

.menuItem button {
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  transition: all 0.3s ease;
}
