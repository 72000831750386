.searchContainer {
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  position: absolute;
  z-index: 1;
}

.searchBar {
  width: 100%;
  margin-top: 60px;
}

.suggestionsList {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

.suggestionItem {
  padding: 10px;
  cursor: pointer;
}

.suggestionItem:hover {
  background-color: var(--gray-200);
}
