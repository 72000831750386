.sideNav,
.contentWrapper {
  height: calc(100vh - 70px);
}
:has(:global(#viewing-as-alert)) .sideNav,
:has(:global(#viewing-as-alert)) .contentWrapper {
  height: calc(100vh - 120px);
}

.contentWrapper {
  overflow: auto;
  flex-grow: 1;
  box-sizing: border-box;
}

.pageBody {
  height: calc(100vh - 120px);
}
