.categoryCardLink {
  max-width: 700px;
  border: 1px solid var(--gray-300);
  word-break: break-word;
  line-height: 1.2;
  transition: all 0.3s ease;
}

.categoryCardLink:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.svg {
  width: 180px !important;
}

@media screen and (min-width: 1200px) {
  .svg {
    width: 130px !important;
  }
}
