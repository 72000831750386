.page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.wrap {
  width: 100%;
  max-width: 500px;
}

.btnWrap {
  padding-top: 30px;
}
