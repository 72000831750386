.frame {
  width: 100%;
  height: 100%;
  position: fixed;
}

.wrap {
  position: fixed;
  top: -10px;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
