.dialog {
  max-width: 525px !important;
}

.header {
  padding: 10px !important;
  background-color: var(--color-polar);
  border: none !important;
}

.title {
  font-size: 20px !important;
  font-weight: 400 !important;
  background-color: var(--color-polar);
}

.body {
  padding: 10px !important;
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item {
  margin: 0 5px;
  padding: 0;
  list-style: none;
}

.browserIcon {
  width: 86px;
  height: 86px;
}

@media only screen and (min-width: 400px) {
  .item {
    margin: 0 10px;
  }

  .browserIcon {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (min-width: 500px) {
  .item {
    margin: 0 15px;
  }

  .browserIcon {
    width: 124px;
    height: 124px;
  }
}

@media only screen and (min-width: 768px) {
  .header {
    padding: 20px 25px !important;
  }

  .body {
    padding: 25px !important;
  }
}
